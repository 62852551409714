import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

import {MaterialModule} from '../material-module';

import { CovalentStepsModule  } from '@covalent/core/steps';
import {CovalentLayoutModule, CovalentLoadingModule} from '@covalent/core';

import { APP_ROUTING } from './app.routes';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { HttpClientModule} from '@angular/common/http';
import { AuthDatasource } from './services/AuthDatasource';

import {FlexLayoutModule} from '@angular/flex-layout';

import { RoleGuardService } from './services/role-guard.service'
import { AuthService } from './services/auth.service';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';

import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import {
  MatDatetimepickerModule,
  MAT_DATETIME_FORMATS
} from '@mat-datetimepicker/core';
import {MatMomentDatetimeModule} from '@mat-datetimepicker/moment';
import { MAT_DATE_LOCALE } from '@angular/material';



//ngprime
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {MenuItem} from 'primeng/api';   
import { NgPrimeModule } from '../ngprimemodule';

import { ToastrModule } from 'ng6-toastr-notifications';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
//import { registerLocaleData } from '@angular/common';

import { AddcodeComponent } from './components/code/addcode/addcode.component';
import { ContactComponent } from './components/contact/contact/contact.component';





import en from '@angular/common/locales/en';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import {ConfirmationService} from 'primeng/api';
import { ViewComponent } from './components/user/view/view.component';
import { ViewuserComponent } from './components/user/viewuser/viewuser.component';
import { NewuserComponent } from './components/user/newuser/newuser.component';
import { EdituserComponent } from './components/user/edituser/edituser.component';

import { RoomComponent } from './components/room/room/room.component';

import {DateFormatPipe} from 'ngx-moment';

import { SearchComponent } from './components/comment/search/search.component';


import {TreeTableModule} from 'primeng/treetable';
import {
  TreeModule
} from 'primeng/primeng';


import { AddroomComponent } from './components/room/addroom/addroom.component';
import { ViewroomComponent } from './components/room/viewroom/viewroom.component';
import { EditroomComponent } from './components/room/editroom/editroom.component';
import { AboutComponent } from './components/about/about/about.component';
import { InstallationComponent } from './components/installation/installation/installation.component';
import { ServiceComponent } from './components/service/service/service.component';
import { PhilosophyComponent } from './components/philosophy/philosophy/philosophy.component';
import { IndexComponent } from './components/index/index/index.component';
import { ChooseComponent } from './components/choose/choose/choose.component';
import { EditinfoComponent } from './components/room/editinfo/editinfo.component';
//registerLocaleData(en);



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminLayoutComponent,
    AddcodeComponent,
    ContactComponent,
    RoomComponent,
    AddroomComponent,
    AboutComponent,

    ViewComponent,
    ViewuserComponent,
    NewuserComponent,
    EdituserComponent,
    SearchComponent,
    ViewroomComponent,
    EditroomComponent,
    AboutComponent,
    InstallationComponent,
    ServiceComponent,
    PhilosophyComponent,
    IndexComponent,
    ChooseComponent,
    EditinfoComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    MaterialModule,
    CovalentLayoutModule,
    CovalentStepsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CovalentLoadingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
    AccordionModule,
    NgPrimeModule,
    TreeTableModule,
    TreeModule,
    ToastrModule.forRoot(),
    NgZorroAntdModule,
    MDBBootstrapModule.forRoot(),
  ],
  exports: [
    MaterialModule,    
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es'},
    { provide: NZ_I18N, useValue: en_US },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    AuthDatasource,
    RoleGuardService,
    AuthService,
    JwtHelperService,
    ConfirmationService,
    DateFormatPipe,


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
