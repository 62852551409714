import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TdLoadingService } from '@covalent/core';
import { UploadFile } from 'ng-zorro-antd';
import { first } from 'rxjs/operators';
import { InstallationService } from 'src/app/services/_installation.service';
@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss']
})
export class InstallationComponent implements OnInit {

  @ViewChild('div') div:ElementRef;

  installation:any;
  htmlContent:any;
  featuredImage: string | null = null;
  featuredImage2: string | null = null;
  featuredImage3: string | null = null;

  submit=true;

  formGroup = new FormGroup({
    description: new FormControl(''),
    description_photo1: new FormControl(''),
    description_photo2: new FormControl(''),
    description_photo3:new FormControl(''),
  });


  constructor(private sanitizer: DomSanitizer,
    private _installation_service:InstallationService,
    private activateRoute:ActivatedRoute,private snackBar: MatSnackBar,private loadingService: TdLoadingService,private router: Router) {
      this.activateRoute.params.subscribe( params => {
        this.getAbout();
      });
   }

  ngOnInit() {
  }

  getImage(url: string): SafeStyle {
    if (url != null && url !== '') {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
    return this.sanitizer.bypassSecurityTrustStyle('url(assets/no-image.png)');
  }

  getAbout() {
    setTimeout(() => {
      this._installation_service.getinstallation().subscribe(data => {
        this.installation = data;
        this.featuredImage = this.installation.url_photo1;
        this.featuredImage2 = this.installation.url_photo2; 
        this.featuredImage3 = this.installation.url_photo3; 

      }, error => {
      }
      );
    }, 200);
  }

  onSubmit(){

    if (this.featuredImage == null) {
      this.snackBar.open('Imagen 1 vacia', 'Entendido', {duration: 5000});
      return;
    }

    if (this.featuredImage2 == null) {
      this.snackBar.open('Imagen 2 vacia', 'Entendido', {duration: 5000});
      return;
    }
    if (this.featuredImage3 == null) {
      this.snackBar.open('Imagen 3 vacia', 'Entendido', {duration: 5000});
      return;
    }
    if (this.formGroup.invalid) {
      this.snackBar.open('Error, uno o mas campos no cumplen con las condiciones', 'Entendido', {duration: 5000});
      return;
    }

    this.installation.description = this.formGroup.value['description'];
    this.installation.description_photo1 = this.formGroup.value['description_photo1'];
    this.installation.description_photo2 = this.formGroup.value['description_photo2'];
    this.installation.description_photo3 = this.formGroup.value['description_photo3'];
    this.installation.url_photo1 = this.featuredImage;
    this.installation.url_photo2 = this.featuredImage2;
    this.installation.url_photo3 = this.featuredImage3;

    if(this.submit){
      this.loadingService.register('loading');
    setTimeout(() => {
      this.submit= false;
      this._installation_service.putinstallation(this.installation).pipe(first()).subscribe(data => {
        this.loadingService.resolve('loading');
        this.submit=true;
        this.snackBar.open('Editado correctamente ', 'Entendido', {duration: 5000});
        }, error => {
          this.loadingService.resolve('loading');
          this.submit=true;
          this.snackBar.open('Ocurrio un error inesperado', 'Entendido', {duration: 5000});
        }
      );
    });
  }
  }





  handleChange(info: { file: UploadFile }): void {

    const originFile = info.file.originFileObj;
    if (originFile == null) {
      return;
    }
    this.getBase64(originFile, (img: string) => {
      this.featuredImage = img;
    });
  }
  
  handleChange2(info: { file: UploadFile }): void {
  
    const originFile = info.file.originFileObj;
    if (originFile == null) {
      return;
    }
    this.getBase64(originFile, (img: string) => {
      this.featuredImage2 = img;
    });
  }

  handleChange3(info: { file: UploadFile }): void {
  
    const originFile = info.file.originFileObj;
    if (originFile == null) {
      return;
    }
    this.getBase64(originFile, (img: string) => {
      this.featuredImage3 = img;
    });
  }


  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (reader.result != null) {
        const base64Img = reader.result.toString() ? reader.result.toString() : '';
        callback(base64Img);
      }
    };
    reader.readAsDataURL(img);
  }

}