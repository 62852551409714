import { Component, OnInit, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UploadFile } from 'ng-zorro-antd';
import { MatSnackBar } from '@angular/material';
import { first } from 'rxjs/operators';
import { TdLoadingService } from '@covalent/core';
import { Router } from '@angular/router';
import { DateFormatPipe } from 'ngx-moment';
import { RoomService } from 'src/app/services/_room.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

interface GalleryImage {
  uid: number;
  name: string;
  status: string;
  url: string;
  thumbUrl: string;
}

interface RoomPost {
  name: string,
  description:string,
  details:string
  photo_room:string,
  banner_room:string,
  photographs:any
}

@Component({
  selector: 'app-addroom',
  templateUrl: './addroom.component.html',
  styleUrls: ['./addroom.component.scss']
})
export class AddroomComponent implements OnInit {
  text: string;
  featuredImage: string | null = null;
  featuredImageBanner: string | null = null;

  desp = [];

  galleryImages: GalleryImage[] = [];
  previewGalleryImage: string | undefined = '';
  previewVisible = false;
  submit=true;

  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  };

  handlePreview = (info: UploadFile) => {

    const originFile = info.file.originFileObj;
    if (originFile == null) {
      return;
    }

    this.getBase64(originFile, (img: string) => {
      this.previewGalleryImage = img;
    });
    this.previewVisible = true;
  };


  constructor(private formBuilder: FormBuilder,public toastr: ToastrManager,private snackBar: MatSnackBar,private router: Router,
    private room_service:RoomService, private dateFormat: DateFormatPipe,private loadingService: TdLoadingService) { }

  ngOnInit() {
    this.dynamicForm = this.formBuilder.group({
     // numberdescriptionimage: ['', Validators.required],
      images: new FormArray([])
  });
  }

  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.images as FormArray; }

  @ViewChildren('inputval') inputval;


  onChangeImages(e) {
    const numberOfImages = e || 0;
    if (this.t.length < numberOfImages) {
        for (let i = this.t.length; i < numberOfImages; i++) {
            this.t.push(this.formBuilder.group({
                description: ['', Validators.required],
            }));
        }
    } else {
        for (let i = this.t.length; i >= numberOfImages; i--) {
            this.t.removeAt(i);
            this.desp.splice(i);
        }
    }
}


  minDate: Date;
  namefile:String = "";
  dynamicForm: FormGroup;

  formGroup = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    details: new FormControl(''),
  });

  rooms:RoomPost={
    name:'',
    description:'',
    details:'',
    photo_room : '',
    banner_room:'',
    photographs:[]
  };
  
  onSubmit(){

    console.log(this.text);

    if (this.dynamicForm.invalid) {
      this.snackBar.open('Error, uno o mas campos no cumplen con las condiciones', 'Entendido', {duration: 5000});
      return;
    }

    if (this.featuredImage == null) {
      this.snackBar.open('El viaje debe tener una imagen destacada', 'Entendido', {duration: 5000});
      return;
    }

    if (this.text == null) {
      this.snackBar.open('Por favor introduce los detalles del evento', 'Entendido', {duration: 5000});
      return;
    }
    if (this.rooms.photographs < 3) {
      this.snackBar.open('La habitación debe tener al menos 3 imágenes de galería', 'Entendido', {duration: 5000});
      return;
    }

    if (this.formGroup.invalid) {
      this.snackBar.open('Error, uno o mas campos no cumplen con las condiciones', 'Entendido', {duration: 5000});
      return;
    }

    this.rooms.details = this.formGroup.value['details'];
    this.rooms.description = this.formGroup.value['description'];
    this.rooms.name = this.formGroup.value['name'];
    this.rooms.photo_room = this.featuredImage;
    this.rooms.banner_room = this.featuredImageBanner;

    if(this.submit){
      this.loadingService.register('loading');
    setTimeout(() => {
      this.submit= false;
      this.room_service.postRoom(this.rooms).pipe(first()).subscribe(data => {
        this.loadingService.resolve('loading');
        this.submit=true;
        this.snackBar.open('Habitación dada de alta ', 'Entendido', {duration: 5000});
        var id = data['id'];
        this.router.navigate(['/admin/room/'+ id]);
        }, error => {
          this.loadingService.resolve('loading');
          this.submit=true;
          this.snackBar.open('Ocurrio un error inesperado', 'Entendido', {duration: 5000});
        }
      );
    });
  }
  }

  handleFileSelect(evt){
    var files = evt.target.files;
    var file = files[0];  
  }


 dangerFile() {
  this.toastr.errorToastr('subir formato png,jpg o pdf', 'Formato de archivo no valido');
}

dangerFilesize() {
  this.toastr.errorToastr('subir archivo de un 1mb o menos', 'Tamaño de archivo no valido');
}


handleChange(info: { file: UploadFile }): void {

  const originFile = info.file.originFileObj;
  if (originFile == null) {
    return;
  }
  this.getBase64(originFile, (img: string) => {
    this.featuredImage = img;
  });
}

handleChangeBanner(info: { file: UploadFile }): void {

  const originFile = info.file.originFileObj;
  if (originFile == null) {
    return;
  }
  this.getBase64(originFile, (img: string) => {
    this.featuredImageBanner = img;
  });
}

handleChangeImages(info: { file: UploadFile }): void {
  setTimeout(() => {
    this.onChangeImages(this.rooms.photographs.length);
  }, 200);
}

changedescription(i){
this.rooms.photographs[i]['description'] = this.desp[i];
}

private getBase64(img: File, callback: (img: string) => void): void {
  const reader = new FileReader();
  reader.onloadend = (e) => {
    if (reader.result != null) {
      const base64Img = reader.result.toString() ? reader.result.toString() : '';
      callback(base64Img);
    }
  };
  reader.readAsDataURL(img);
}

}
