import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { 
    RoleGuardService as RoleGuard 
  } from './services/role-guard.service';  
import { AdminLayoutComponent } from './components/admin-layout/admin-layout.component';
import { ViewComponent } from './components/user/view/view.component';
import { ViewuserComponent } from './components/user/viewuser/viewuser.component';
import { NewuserComponent } from './components/user/newuser/newuser.component';
import { EdituserComponent } from './components/user/edituser/edituser.component';
import { RoomComponent } from './components/room/room/room.component';
import { SearchComponent } from './components/comment/search/search.component';
import { AddcodeComponent } from './components/code/addcode/addcode.component';
import { ContactComponent } from './components/contact/contact/contact.component';
import { AddroomComponent } from './components/room/addroom/addroom.component';
import { ViewroomComponent } from './components/room/viewroom/viewroom.component';
import { EditroomComponent } from './components/room/editroom/editroom.component';
import { AboutComponent } from './components/about/about/about.component';
import { InstallationComponent } from './components/installation/installation/installation.component';
import { ServiceComponent } from './components/service/service/service.component';
import { PhilosophyComponent } from './components/philosophy/philosophy/philosophy.component';
import { IndexComponent } from './components/index/index/index.component';
import { ChooseComponent } from './components/choose/choose/choose.component';
import { EditinfoComponent } from './components/room/editinfo/editinfo.component';

const APP_ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path:'generate/code',
        component:AddcodeComponent
      },
      {
        path: 'search/comment',
        component: SearchComponent
        
      },
      {
        path:'contact',
        component:ContactComponent
      },
      {
        path:'room',
        component:RoomComponent
      },
      {
        path:'room/add',
        component:AddroomComponent
      },
      {
        path:'room/edit/:id',
        component:EditroomComponent
      },
      {
        path:'room/:id',
        component:ViewroomComponent
      },
      {
        path:'about',
        component:AboutComponent
      },
      {
        path:'installation',
        component:InstallationComponent
      },
      {
        path:'service',
        component:ServiceComponent
      },
      {
        path:'philosophy',
        component:PhilosophyComponent
      },
      {
        path:'index',
        component:IndexComponent
      },
      {
        path:'choose',
        component:ChooseComponent
      },
      {
        path:'edit/info/room',
        component:EditinfoComponent
      },
      {
        path:'users',
        component:ViewComponent
      },
      {
        path:'user/:id',
        component:ViewuserComponent
      },
      {
        path:'user/add/new',
        component:NewuserComponent
      },
      {
        path:'user/add/edit/:id',
        component:EdituserComponent
      },
    ],canActivate: [RoleGuard], 
    data: { 
      expectedRole: 'ADMIN'
    },},

    { path: '**',pathMatch:'full',redirectTo:'login'}
];


export const APP_ROUTING = RouterModule.forRoot (APP_ROUTES);
