import { Component, OnInit, ViewChild } from '@angular/core';
import { USer } from 'src/app/interfaces/User.Interface';
import { Router, ActivatedRoute } from '@angular/router';
import { TdLoadingService } from '@covalent/core';
import { MatSnackBar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';


export interface Study {
  value: any;
  viewValue: string;
}

export interface State {
  value: any;
  viewValue: string;
} 

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {

  mode = '';
  adminUser?: any;

  adminUserData : any = {};

  user_id='';

  study: Study[] = [
    {value: 1, viewValue: 'Pasante'},
    {value: 2, viewValue: 'Estudiante'},
    {value: 3, viewValue: 'Tecnico'},
    {value: 4, viewValue: 'Tecnico Superior Universitario'},
    {value: 5, viewValue: 'Licenciado'},
    {value: 5, viewValue: 'Maestro'},
    {value: 5, viewValue: 'Doctor'},

  ];

  states: State[] = [
    {value: 1, viewValue: 'Aguascalientes'},
    {value: 2, viewValue: 'Baja California'},
    {value: 3, viewValue: 'Baja California Sur'},
    {value: 4, viewValue: 'Campeche'},
    {value: 5, viewValue: 'Chiapas'},
    {value: 6, viewValue: 'Chihuahua'},
    {value: 7, viewValue: 'Coahuila'},
    {value: 8, viewValue: 'Colima'},
    {value: 9, viewValue: 'Durango'},
    {value: 10, viewValue: 'Estado de México'},
    {value: 11, viewValue: 'Guanajuato'},
    {value: 12, viewValue: 'Guerrero'},
    {value: 13, viewValue: 'Hidalgo'},
    {value: 14, viewValue: 'Jalisco'},
    {value: 15, viewValue: 'Michoacán de Ocampo'},
    {value: 16, viewValue: 'Morelos'},
    {value: 17, viewValue: 'Nayarit'},
    {value: 18, viewValue: 'Nuevo León'},
    {value: 19, viewValue: 'Oaxaca'},
    {value: 20, viewValue: 'Puebla'},
    {value: 21, viewValue: 'Querétaro'},
    {value: 22, viewValue: 'Quintana Roo'},
    {value: 23, viewValue: 'San Luis Potosí'},
    {value: 24, viewValue: 'Sinaloa'},
    {value: 25, viewValue: 'Sonora'},
    {value: 26, viewValue: 'Tabasco'},
    {value: 27, viewValue: 'Tamaulipas'},
    {value: 28, viewValue: 'Tlaxcala'},
    {value: 29, viewValue: 'Veracruz '},
    {value: 30, viewValue: 'Yucatán'},
    {value: 31, viewValue: 'Zacatecas'},
  ];

  constructor(
    private router: Router,
    private loadingService: TdLoadingService,
    private snackBar: MatSnackBar,
    private user_service:UserService,
    public toastr: ToastrManager,
    private activateRoute:ActivatedRoute
      ) {
        this.activateRoute.params.subscribe( params => {
          this.user_id = params['id'];
          this.getUser(params['id']);
        });
  }

  ngOnInit() {
  }


  onSubmit(form: FormControl) {

    this.adminUserData.name = this.adminUser.name;
    this.adminUserData.last_name = this.adminUser.last_name;
    this.adminUserData.password = this.adminUser.password;
    this.adminUserData.photo = this.adminUser.photo;
    console.log(this.adminUserData);

    if (form.invalid) {
      return;
    }

    if (this.adminUserData == null) {
      return;
    }

    this.loadingService.register('loading');
    this.user_service.putUser(this.adminUserData,this.user_id)
      .subscribe(data => {
        this.loadingService.resolve('loading');
        this.snackBar.open('El usuario se guardó', 'Entendido', {duration: 5000});
        this.router.navigate(['/admin/user/' + data['id']]);
      }, err => {
        this.snackBar.open('Occurrio un error al guardar el usuario', 'Entendido', {duration: 5000});
        this.loadingService.resolve('loading');
      });
  }


  notvalidsize($url:string){
    this.dangerimagesize();
   }
  
   notvalidimage($url:string){
    this.dangerimage();
  }
  
  viewimage($url:string){
    this.adminUser.photo = $url;
   }


   dangerimage() {
    this.toastr.errorToastr('subir imagen jpg o png', 'Formato de imagen no valido');
  }
  dangerimagesize() {
    this.toastr.errorToastr('subir imagen de un 1mb o menos', 'Tamaño de imagen no valido');
  }

  updatenotchange() {
    this.toastr.errorToastr('', 'Cambia al menos un valor');
  }
  updateerror() {
    this.toastr.errorToastr('intentalo mas tarde', 'Ocurrio un error inesperado');
  }


  getUser(id:any) {
    setTimeout(() => {
      this.user_service.getUser(id).subscribe(data => {
        this.adminUser = data;
        this.adminUser['password'] = '';
      }, error => {
      }
      );
    }, 200);
  }

}




