import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { USer } from 'src/app/interfaces/User.Interface';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-viewuser',
  templateUrl: './viewuser.component.html',
  styleUrls: ['./viewuser.component.scss']
})
export class ViewuserComponent implements OnInit {

  adminUser?: any;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private user_service:UserService,
    private activateRoute:ActivatedRoute
  ) {
    this.activateRoute.params.subscribe( params => {
      this.getUser(params['id']);
    });

  }

  ngOnInit() {
    this.adminUser = this.route.snapshot.data.data;
  }

  getUserPhoto(url: string): SafeStyle {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }


    getUser(id:any) {
      setTimeout(() => {
        this.user_service.getUser(id).subscribe(data => {
          this.adminUser = data;
        }, error => {
        }
        );
      }, 200);
    }


}


