import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TdLoadingService } from '@covalent/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommentService } from 'src/app/services/_comment.service';
@Component({
  selector: 'app-viewdeposits',
  templateUrl: './addcode.component.html',
  styleUrls: ['./addcode.component.scss']
})
export class AddcodeComponent implements OnInit {
  display = false;
  submit=true;
  checked:Boolean = false;
  disabled:Boolean = false;
  datacode:any = {
    name : '',
    last_name:'',
    phone:'',
    email:''
  }
  code = "";
  
  
    constructor(public toastr: ToastrManager,private snackBar: MatSnackBar,
      private _datacode_service:CommentService,private loadingService: TdLoadingService) { 
      }
  
    ngOnInit() {
    }
  
  
    formGroup = new FormGroup({
      name: new FormControl(''),
      last_name: new FormControl(''),
      phone: new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      email: new FormControl('',[Validators.email]),
    });
  
  
  
    onSubmit(){
  
      if (this.formGroup.invalid) {
        this.snackBar.open('Error, uno o mas campos no cumplen con las condiciones', 'Entendido', {duration: 5000});
        return;
      }
  
      this.datacode.name = this.formGroup.value['name'];
      this.datacode.last_name = this.formGroup.value['last_name'];
      this.datacode.phone = this.formGroup.value['phone'];
      this.datacode.email = this.formGroup.value['email'];
      
    
      if(this.submit){
        this.loadingService.register('loading');

     setTimeout(() => {
        this.submit= false;
        this._datacode_service.postCode(this.datacode).pipe(first()).subscribe(data => {
          this.loadingService.resolve('loading');
          this.submit=true;
          this.display = true;
          this.code = data['code'];
          this.snackBar.open('Código generado correctamente ', 'Entendido', {duration: 5000});
          }, error => {
            this.loadingService.resolve('loading');
            this.submit=true;
            this.snackBar.open('Ocurrio un error inesperado', 'Entendido', {duration: 5000});
          }
        );
      });
    }
    }
  
  
    changestatus($status:Boolean){
      this.checked = $status['checked'];
      }    
  
    closewindow(){
      this.datacode.name = "";
      this.datacode.last_name = "";
      this.datacode.phone ="";
      this.datacode.email = "";
      this.formGroup.value['name'] = "";
      this.formGroup.value['last_name']="";
      this.formGroup.value['phone']="";
      this.formGroup.value['email']="";
      this.display = false;

    }
  

  
  }