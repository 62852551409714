import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TdLoadingService } from '@covalent/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChooseService } from 'src/app/services/_choose.service';

@Component({
  selector: 'app-choose',
  templateUrl: './choose.component.html',
  styleUrls: ['./choose.component.scss']
})
export class ChooseComponent implements OnInit {
  display = false;
  submit=true;
  color = 'accent';
  checked:Boolean = false;
  disabled:Boolean = false;
  choose:any;
  
  
  id:any;
    constructor(public toastr: ToastrManager,private snackBar: MatSnackBar,
      private _choose_service:ChooseService,private loadingService: TdLoadingService) { 
        this.getchoose();
      }
  
    ngOnInit() {
    }
  
  
    formGroup = new FormGroup({
      description: new FormControl(''),
      text1_title: new FormControl(''),
      text2_title: new FormControl(''),
      text3_title: new FormControl(''),
      text1_subtitle: new FormControl(''),
      text2_subtitle: new FormControl(''),
      text3_subtitle: new FormControl(''),
    });
  
  
  
    onSubmit(){
  
      if (this.formGroup.invalid) {
        this.snackBar.open('Error, uno o mas campos no cumplen con las condiciones', 'Entendido', {duration: 5000});
        return;
      }
  
      this.choose.description = this.formGroup.value['description'];
      this.choose.text1_title = this.formGroup.value['text1_title'];
      this.choose.text2_title = this.formGroup.value['text2_title'];
      this.choose.text3_title = this.formGroup.value['text3_title'];
      this.choose.text1_subtitle = this.formGroup.value['text1_subtitle'];
      this.choose.text2_subtitle = this.formGroup.value['text2_subtitle'];
      this.choose.text3_subtitle = this.formGroup.value['text3_subtitle'];
  
    
      if(this.submit){
        this.loadingService.register('loading');
        this.display = true;

     setTimeout(() => {
        this.submit= false;
        this._choose_service.putChoose(this.choose).pipe(first()).subscribe(data => {
          this.loadingService.resolve('loading');
          this.submit=true;
          this.snackBar.open('Datos editados correctamente ', 'Entendido', {duration: 5000});
          }, error => {
            this.loadingService.resolve('loading');
            this.submit=true;
            this.snackBar.open('Ocurrio un error inesperado', 'Entendido', {duration: 5000});
          }
        );
      });
    }
    }
  
  
    changestatus($status:Boolean){
      this.checked = $status['checked'];
      }    
  
  
  getchoose() {
    setTimeout(() => {
      this._choose_service.getChoose().subscribe(data => {
        this.choose = data;
      }, error => {
      }
      );
    }, 200);
  }
  
  }