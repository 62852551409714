import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { QueryService } from './query.service';
import { USer, UserRol } from '../interfaces/User.Interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

 
  constructor(private http:HttpClient,private router:Router,private query:QueryService) {
   }

   getUsers(){
    return this.query.getQuery('panel/view/user');
   }

   getUser(id:any){
    return this.query.getQuery('panel/view/user/'+id);
   }

   postUser(user:USer){
    return this.query.postQuery('panel/add/user',user); 
   }

   putUser(user,id:any){
    return this.query.putQuery('panel/update/user/'+id,user); 
   }


   deleteuser(id){
    return this.query.deleteQuery('panel/delete/user/' + id); 
   }
   
   

}
