import { Injectable } from '@angular/core';
import { QueryService } from './query.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

 
  constructor(private query:QueryService) {
   }

 
   getservice(){
    return this.query.getQuery('panel/view/service');
   }

   putservice(data){
    return this.query.putQuery('panel/update/service',data);
   }



}
