import { Component } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bazzarcito';
}
