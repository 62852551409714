import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  panelMode: string | null;
  agencyId: string | null;

  mail?: string | null;

  constructor(
    private router: Router
  ) {
    this.panelMode = localStorage.getItem('panel_mode');
    this.agencyId = localStorage.getItem('agency_id');
    this.mail = localStorage.getItem('mail');

    if (localStorage.getItem('token') == null || localStorage.getItem('token') === '') {
      router.navigate(['/login']);
    }
  }

  ngOnInit() {

  }

  logout() {
    localStorage.setItem('token', '');
    this.router.navigate(['/login']);
  }

}
