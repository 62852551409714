import { Injectable } from '@angular/core';
import { QueryService } from './query.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

 
  constructor(private query:QueryService) {
   }

   postCode(data){
    return this.query.postQuery('panel/generate/code',data);
   }

   getComments(word){
    return this.query.getQuery('panel/search/comment/' + word);
   }


   deleteComment(data){
    return this.query.postQuery('panel/delete/comment',data);
   }

}
