import { Component, OnInit } from '@angular/core';
import { TdLoadingService } from '@covalent/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CommentService } from 'src/app/services/_comment.service';
import { ConfirmationService } from 'primeng/api';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchDirty = false;
  comment = "";
  comments:any = [];
  password:any = {};
  display = false;
  id:any;
  delcomment : any = {};

  constructor(private comment_service: CommentService,
    private loadingService: TdLoadingService,
    public toastr: ToastrManager,
    private confirmationService: ConfirmationService,
    private snackBar: MatSnackBar,
    ) {

     }

  ngOnInit() {
  }

  search = '';
  dialog:Boolean = false;

  keyUp(event){
   this.search = this.search.trim().toLowerCase();
}

  searchSale() {
    if(this.search == ''){
      this.toastr.errorToastr('Introduce alguna palabra o nombre', 'El campo de texto no puede ir en blanco');
      return;
    }
    this.loadingService.register('loading');
   setTimeout(() => {
      this.comment_service.getComments(this.search).subscribe(data => {
          this.searchDirty = true;
          this.comments = data;
          this.loadingService.resolve('loading');
        }, error => {
          this.loadingService.resolve('loading');
        }
      );
    });
  }


  viewdetails(data){
   this.display = true;
   this.comment = data.comment;
   this.id = data.id;
  }


  confirm(id) {
    this.display = false;
    this.confirmationService.confirm({
      message: '¿Seguro que desea eliminar este comentario?, esta acción no se puede deshacer',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:'Aceptar',
      accept: () => {
      this.deletecomment(id); 
      },
      reject: () => {
              
      }
  });
}

deletecomment(id) {
  this.loadingService.register('loading');
  this.delcomment.comment_id = id;
 setTimeout(() => {
    this.comment_service.deleteComment(this.delcomment).subscribe(data => {
      this.snackBar.open('Comentario eliminado con éxito', 'Entendido', {duration: 5000});
      this.searchSale();
      this.loadingService.resolve('loading');
      }, error => {
        this.loadingService.resolve('loading');
      }
    );
  });
}


  
}
