import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { RoomService } from 'src/app/services/_room.service';

@Component({
  selector: 'app-viewroom',
  templateUrl: './viewroom.component.html',
  styleUrls: ['./viewroom.component.scss']
})
export class ViewroomComponent implements OnInit {
  @ViewChild('div') div:ElementRef;

  room:any;
  htmlContent:any;


  constructor(private sanitizer: DomSanitizer,
    private _room_service:RoomService,
    private activateRoute:ActivatedRoute) {
      this.activateRoute.params.subscribe( params => {
        this.getRoom(params['id']);
      });
   }

  ngOnInit() {
  }

  getImage(url: string): SafeStyle {
    if (url != null && url !== '') {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
    return this.sanitizer.bypassSecurityTrustStyle('url(assets/no-image.png)');
  }

  getRoom(id) {
    setTimeout(() => {
      this._room_service.getRoomid(id).subscribe(data => {
        this.room = data;
        this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.room.details);
        this.div.nativeElement.innerHTML =this.room.details;
      }, error => {
      }
      );
    }, 200);
  }

}
