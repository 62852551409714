import { Component, OnInit } from '@angular/core';
import {  UserRol } from 'src/app/interfaces/User.Interface';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationService } from 'primeng/api';
import { MatSnackBar } from '@angular/material';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  adminUsers: any;
  userrol:UserRol ={
    user_id:''
  }

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private user_service:UserService,
    private confirmationService: ConfirmationService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.getUsers(); 
 }

  getUserPhoto(url: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }

    getUsers() {
      setTimeout(() => {
        this.user_service.getUsers().subscribe(data => {
          this.adminUsers = data;
          console.log(this.adminUsers);
        }, error => {
        }
        );
      }, 200);
    }


   adminnot(id){
     this.userrol.user_id = id;
    setTimeout(() => {
      this.user_service.deleteuser(this.userrol.user_id).pipe(first()).subscribe(data => {
        this.adminUsers = data;
        this.getUsers();
      }, error => {
        this.snackBar.open('No se realizo ninguna acción', 'Entendido', {duration: 5000});
      }
      );
    }, 200);
    }


    confirm(id) {
      this.confirmationService.confirm({
        message: '¿Quitar como administrador?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel:'Si',
        accept: () => {
                 this.adminnot(id); 
        },
        reject: () => {
                
        }
    });
  }
}
