import { Injectable } from '@angular/core';
import { QueryService } from './query.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

 
  constructor(private query:QueryService) {
   }

 
   getRoom(){
    return this.query.getQuery('panel/view/room');
   }

   getRoomid(id){
    return this.query.getQuery('panel/view/room/' + id);
   }

   postRoom(data){
    return this.query.postQuery('panel/add/room',data);
   }

   putRoom(data,id){
    return this.query.putQuery('panel/update/room/'+id,data);
   }

   deleteRoom(data){
    return this.query.postQuery('panel/delete/room',data);
   }


   getRoominfo(){
    return this.query.getQuery('panel/view/inforoom');
   }

   putRoominfo(data){
    return this.query.putQuery('panel/update/inforoom',data);
   }



}
