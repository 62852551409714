import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, IAppConfig} from '../app.config';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class AuthDatasource {
  baseUrl: string ="https://api.suitesmasaryk.com/";
  //baseUrl="http://localhost:8000/";

  config ={
    data:JSON.parse(localStorage.getItem('currentUser'))
    }

  constructor(
    private http: HttpClient,
  ) {
  }

  login(mail: string, password: string): Observable<boolean> {
    return this.http
      .post(this.baseUrl + 'panel/auth/user', {
        mail, password
      })
      .pipe(
        map((rs: any) => {
          if (rs.roles[0].rol === 'ADMIN') {
            localStorage.setItem('currentUser', JSON.stringify(rs));
            localStorage.setItem('token', rs.auth_token);
            localStorage.setItem('mail', rs.mail);
            localStorage.setItem('panel_mode', 'ADMIN');
          }
          return true;
        }));
  }
}
