import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TdLoadingService } from '@covalent/core';
import { UploadFile } from 'ng-zorro-antd';
import { first } from 'rxjs/operators';
import { RoomService } from 'src/app/services/_room.service';

@Component({
  selector: 'app-editinfo',
  templateUrl: './editinfo.component.html',
  styleUrls: ['./editinfo.component.scss']
})
export class EditinfoComponent implements OnInit {

  @ViewChild('div') div:ElementRef;

  inforoom:any;
  htmlContent:any;
  featuredImage: string | null = null;
  submit=true;

  formGroup = new FormGroup({
    text1: new FormControl(''),
    text2: new FormControl(''),
  });


  constructor(private sanitizer: DomSanitizer,
    private _room_service:RoomService,
    private activateRoute:ActivatedRoute,private snackBar: MatSnackBar,private loadingService: TdLoadingService,private router: Router) {
      this.activateRoute.params.subscribe( params => {
        this.getInfoRoom();
      });
   }

  ngOnInit() {
  }

  getImage(url: string): SafeStyle {
    if (url != null && url !== '') {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
    return this.sanitizer.bypassSecurityTrustStyle('url(assets/no-image.png)');
  }

  getInfoRoom() {
    setTimeout(() => {
      this._room_service.getRoominfo().subscribe(data => {
        this.inforoom = data;
        this.featuredImage = this.inforoom.url;
      }, error => {
      }
      );
    });
  }

  onSubmit(){

    if (this.featuredImage == null) {
      this.snackBar.open('Debe contener una imagen', 'Entendido', {duration: 5000});
      return;
    }

    if (this.formGroup.invalid) {
      this.snackBar.open('Error, uno o mas campos no cumplen con las condiciones', 'Entendido', {duration: 5000});
      return;
    }

    this.inforoom.text1 = this.formGroup.value['text1'];
    this.inforoom.text2 = this.formGroup.value['text2'];
    this.inforoom.url = this.featuredImage;

    if(this.submit){
      this.loadingService.register('loading');
    setTimeout(() => {
      this.submit= false;
      this._room_service.putRoominfo(this.inforoom).pipe(first()).subscribe(data => {
        this.loadingService.resolve('loading');
        this.submit=true;
        this.snackBar.open('Editado correctamente ', 'Entendido', {duration: 5000});
        }, error => {
          this.loadingService.resolve('loading');
          this.submit=true;
          this.snackBar.open('Ocurrio un error inesperado', 'Entendido', {duration: 5000});
        }
      );
    });
  }
  }





  handleChange(info: { file: UploadFile }): void {

    const originFile = info.file.originFileObj;
    if (originFile == null) {
      return;
    }
    this.getBase64(originFile, (img: string) => {
      this.featuredImage = img;
    });
  }
  


  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (reader.result != null) {
        const base64Img = reader.result.toString() ? reader.result.toString() : '';
        callback(base64Img);
      }
    };
    reader.readAsDataURL(img);
  }

}
