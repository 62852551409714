import { Component, OnInit, ViewChildren, ElementRef } from '@angular/core';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { RoomService } from 'src/app/services/_room.service';
import { ConfirmationService } from 'primeng/api';
import { TdLoadingService } from '@covalent/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-view',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {

  @ViewChildren(DatatableComponent) datatable?: DatatableComponent;
  @ViewChildren('datatableWrapper') datatableWrapper?: ElementRef;

  report:any;
  rooms:any;
  display = false;
  delroom : any = {};


  ngAfterViewInit() {
      if (this.datatable != null) {
          this.datatable.columnMode = ColumnMode.force;
      }
  }


  constructor(
      private sanitizer: DomSanitizer,
      private _room_service:RoomService,
      private confirmationService: ConfirmationService,
      private loadingService: TdLoadingService,
      private snackBar: MatSnackBar,

  ) {
    this.getRoom();
  }

  ngOnInit() {
      if (this.datatableWrapper != null && this.datatable != null) {
          const datatable = this.datatable;
          const sensor = new ResizeSensor(this.datatableWrapper.nativeElement, () => {
              datatable.recalculate();
              window.dispatchEvent(new Event('resize'));
          });
      }
  }

  getUserPhoto(url: string): SafeStyle {
      if (url != null && url !== '') {
          return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
      }
      return this.sanitizer.bypassSecurityTrustStyle('url(assets/user.jpg)');
  }


  getRoom() {
    setTimeout(() => {
      this._room_service.getRoom().subscribe(data => {
        this.rooms = data;
      }, error => {
      }
      );
    }, 200);
  }



  confirm(id) {
    this.confirmationService.confirm({
      message: '¿Seguro que desea eliminar esta habitación?, esta acción no se puede deshacer',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:'Aceptar',
      accept: () => {
      this.deleteroom(id); 
      },
      reject: () => {
              
      }
  });
}

deleteroom(id) {
  this.loadingService.register('loading');
  this.delroom.room_id = id;
 setTimeout(() => {
    this._room_service.deleteRoom(this.delroom).subscribe(data => {
      this.snackBar.open('Habitación eliminada con éxito', 'Entendido', {duration: 5000});
      this.getRoom();
      this.loadingService.resolve('loading');
      }, error => {
        this.loadingService.resolve('loading');
      }
    );
  });
}

  

}
