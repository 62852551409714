import {NgModule} from '@angular/core';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {CalendarModule} from 'primeng/calendar';
import {PasswordModule} from 'primeng/password';
import {TabViewModule} from 'primeng/tabview';
import {FieldsetModule} from 'primeng/fieldset';
import {CardModule} from 'primeng/card';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {RatingModule} from 'primeng/rating';
import {StepsModule} from 'primeng/steps';
import {SpinnerModule} from 'primeng/spinner';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ToastModule} from 'primeng/toast';
import {GalleriaModule} from 'primeng/galleria';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CarouselModule} from 'primeng/carousel';
import {PaginatorModule} from 'primeng/paginator';
import {EditorModule} from 'primeng/editor';
import {FileUploadModule} from 'primeng/fileupload';



  @NgModule({
    exports: [
      PasswordModule,
      AccordionModule,
      CalendarModule,
      TabViewModule,
      FieldsetModule,
      CardModule,
      InputMaskModule,
      InputTextareaModule,
      RatingModule,
      StepsModule,
      SpinnerModule,
      InputTextModule,
      TableModule,    
      DialogModule,  
      VirtualScrollerModule,
      ToastModule,
      ConfirmDialogModule,
      GalleriaModule,
      CarouselModule,
      PaginatorModule,
      EditorModule,
      FileUploadModule,
    ]
  })
  
  export class NgPrimeModule {}
  