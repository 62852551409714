import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {TdLoadingService} from '@covalent/core';
import { AuthDatasource } from 'src/app/services/AuthDatasource';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formGroup = new FormGroup({
    mail: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    private auth: AuthDatasource,
    private snackBar: MatSnackBar,
    private router: Router,
    private loadingService: TdLoadingService,
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.loadingService.register('loading');
    this.auth.login(this.formGroup.value['mail'], this.formGroup.value['password'])
      .subscribe(() => {
        this.loadingService.resolve('loading');
        this.router.navigate(['/admin/generate/code']).then(() => {
          window.location.reload();
       });
      }, (err: HttpErrorResponse) => {
        this.loadingService.resolve('loading');
        if (err.status === 404) {
          this.snackBar.open('No se encontró el correo proporcionado', 'Entendido', {duration: 5000});
        } else if (err.status === 422) {
          this.snackBar.open('La contraseña no coincide', 'Entendido', {duration: 5000});
        }
      });
  }

}
