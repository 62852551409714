import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';
import { Observable,throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfig, IAppConfig, SesionConfig, Sesion } from '../app.config';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class QueryService {
 url="https://api.suitesmasaryk.com/";
 //url="http://localhost:8000/";
 config ={
  data:JSON.parse(localStorage.getItem('currentUser'))
  }

  newReleases:any = [];
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
      'token': this.config.data ? this.config.data['auth_token'] : '',
      'userid': this.config.data ? this.config.data['id'].toString() : ''
    })
  };



  public httpOptionsdownload = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*',
      'token': this.config.data ? this.config.data['auth_token'] : '',
      responseType:'blob'
    })
  };
 
  constructor(private http:HttpClient,private router:Router,private snackBar: MatSnackBar,
    ) {
   }

   getQuery(query:String){
    const url = this.url + query;
    return this.http.get(url,this.httpOptions).pipe(
      map( response => {
        console.log(this.config.data['auth_token']);

        return response;
      }),
       catchError(error => {
       if(error['status'] == 401){
          this.logout();
        }
       return error;
       })
      );
 }

  postQuery(query:String,post:any){     
   const url = this.url + query; 
   return this.http.post( url, post , this.httpOptions )
           .pipe(delay(500),
           map( response => {
             return response;
           },error =>{
            if(error['status'] == 401){
              this.logout();
            }
           }));
 }

 putQuery( query:string,user:any) {
   const url = this.url + query; 
   return this.http.put( url, user , this.httpOptions )
         .pipe(
         map( response => {
           return response;
         }),
     catchError(error => {
      if(error['status'] == 401){
        this.logout();
      } 
      return error;
     }),
     catchError(error => {
     return error;
     })  
      );
 }


 deleteQuery(query:String){
  const url = this.url + query;
  return this.http.delete(url,this.httpOptions).pipe(
    map( response => {
      return response;
    }),
     catchError(error => {
      if(error['status'] == 401){
        this.logout();
      }
     return error;
     })
    );
}

 Download(query:String,post:any){
  const url = this.url + query;
  return this.http.post(url,post,{ headers:this.httpOptions.headers, responseType: 'blob'}).pipe(
    map( response => {
      return response;
    }),
     catchError(error => {
      if(error['status'] == 401){
        this.logout();
      }
     return error;
     })
    );
}


logout() {
  this.snackBar.open('Por seguridad, unicamente puedes mantener abierta tu sesión en un solo sitio', 'Entendido', {duration: 5000});
  localStorage.setItem('token', '');
  this.router.navigate(['/login']).then(() => {
    window.location.reload();
 });
}

}
