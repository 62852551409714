import { Injectable } from '@angular/core';
import { QueryService } from './query.service';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

 
  constructor(private query:QueryService) {
   }

 
   getabout(){
    return this.query.getQuery('panel/view/about');
   }

   putabout(data){
    return this.query.putQuery('panel/update/about',data);
   }



}
