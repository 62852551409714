import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TdLoadingService } from '@covalent/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ContactService } from 'src/app/services/_contact.service';

@Component({
  selector: 'app-refund',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  display = false;
  submit=true;
  color = 'accent';
  checked:Boolean = false;
  disabled:Boolean = false;
  datasocial:any = {
    landline: '',
    cellphone:'',
    facebook:'',
    email:'',
    linkfacebook:''
  }
  
  
  id:any;
    constructor(public toastr: ToastrManager,private snackBar: MatSnackBar,
      private _datasocial_service:ContactService,private loadingService: TdLoadingService) { 
        this.getdatasocial();
      }
  
    ngOnInit() {
    }
  
  
    formGroup = new FormGroup({
      landline: new FormControl(''),
      cellphone: new FormControl(''),
      facebook: new FormControl(''),
      email: new FormControl(''),
      linkfacebook :new FormControl('')
    });
  
  
  
    onSubmit(){
  
      if (this.formGroup.invalid) {
        this.snackBar.open('Error, uno o mas campos no cumplen con las condiciones', 'Entendido', {duration: 5000});
        return;
      }
  
      this.datasocial.landline = this.formGroup.value['landline'];
      this.datasocial.cellphone = this.formGroup.value['cellphone'];
      this.datasocial.facebook = this.formGroup.value['facebook'];
      this.datasocial.email = this.formGroup.value['email'];
      this.datasocial.linkfacebook = this.formGroup.value['linkfacebook'];
  
    
      if(this.submit){
        this.loadingService.register('loading');
        this.display = true;

     setTimeout(() => {
        this.submit= false;
        this._datasocial_service.putContact(this.datasocial).pipe(first()).subscribe(data => {
          this.loadingService.resolve('loading');
          this.submit=true;
          this.snackBar.open('Datos editados correctamente ', 'Entendido', {duration: 5000});
          }, error => {
            this.loadingService.resolve('loading');
            this.submit=true;
            this.snackBar.open('Ocurrio un error inesperado', 'Entendido', {duration: 5000});
          }
        );
      });
    }
    }
  
  
    changestatus($status:Boolean){
      this.checked = $status['checked'];
      }    
  
  
  getdatasocial() {
    setTimeout(() => {
      this._datasocial_service.getContact().subscribe(data => {
        this.datasocial = data;
      }, error => {
      }
      );
    }, 200);
  }
  
  }